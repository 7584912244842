export enum EnvRessourcePrefix {
    Dev = 'https://dev.effy.quelleenergie.net/',
    Rct = 'https://rct.effy.quelleenergie.net/',
    Prod = 'https://www.effy.fr/'
  }

export enum Environnement {
    Local = 'local',
    Dev = 'dev',
    Rct = 'rct',
    Prod = 'production',
  }

export const ENV_RESSOURCE_MAP = new Map();
ENV_RESSOURCE_MAP.set(Environnement.Local, EnvRessourcePrefix.Dev);
ENV_RESSOURCE_MAP.set(Environnement.Dev, EnvRessourcePrefix.Dev);
ENV_RESSOURCE_MAP.set(Environnement.Rct, EnvRessourcePrefix.Rct);
ENV_RESSOURCE_MAP.set(Environnement.Prod, EnvRessourcePrefix.Prod);
