import { EffyEnvironment } from '@effy-tech/angular-common';
import { EnvironmentPartModel } from '../app/core/models/environment.model';
import { Environnement } from '../index.config';

export const environment: EnvironmentPartModel = {
  production: false,
  env: Environnement.Dev,
  effyEnv: EffyEnvironment.Dev,
  apiKey: 'AIzaSyCRqlDovX8GPnhOW9vOTpg2M5WicpLQhVk',
  api: {
    ecGatewayNginx: 'https://ec.gateway.dev.effyconnect.net/',
    user: 'https://users.dev.api.quelleenergie.net/v1/',
    enrichment: 'https://user-enrichment.dev.api.quelleenergie.net/',
  },
  commonAssetsUrl: 'https://dev.effy.quelleenergie.net/common-assets',
  effyBaseUrl: 'https://dev.effy.quelleenergie.net',
  xApi: {
    sherlock: {
      id: 'Parcours',
      token: '_buxKy6Dtn2Gx7mr5xho',
    },
  },
};
